<template>
  <div class="monitor">
    <page-list-container :total="total" :pageSize="pageSize" :inputCurrentPage="currentPage"
    @onPageChange="onPageChange" @onSizeChange="onSizeChange">
      <div v-if="dataArray.length">
        <el-table :data="dataArray" stripe border>
          <el-table-column fixed prop="sn" label="设备SN" width="130" show-overflow-tooltip />
          <el-table-column prop="ringsn" label="戒指SN" width="130" show-overflow-tooltip />
          <el-table-column prop="createDate" label="时间" width="130" show-overflow-tooltip />
          <el-table-column prop="deviceMonitorStatus" label="睡眠仪监测状态" width="120" show-overflow-tooltip />
          <el-table-column prop="ringMonitorStatus" label="指环监测状态" width="120" show-overflow-tooltip />
          <el-table-column prop="ringWorkStatus" label="指环工作状态" width="120" show-overflow-tooltip />
          <el-table-column prop="ringPower" label="戒指电量" width="80" show-overflow-tooltip />
          <el-table-column prop="isInBed" label="离床" width="60" show-overflow-tooltip />
          <el-table-column prop="netIconURL" label="监护仪网络" width="90" show-overflow-tooltip>
            <template slot-scope="scope">
              <img class="monitor-signal-icon" :src="scope.row.netIconURL" />
            </template>
          </el-table-column>
          <el-table-column prop="monitorDuration" label="监测时段" width="100" show-overflow-tooltip />
          <el-table-column prop="spo2" label="血氧" width="60" />
          <el-table-column prop="version" label="版本号" width="90" />
          <el-table-column prop="ringVersion" min-width="90" label="指环版本号" />
        </el-table>
      </div>
    </page-list-container>


  </div>
</template>

<script>
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import { DateTool } from "@js/date-tool.js";
import { MonitorCellModel } from "@c/operation-log/monitor/monitor-cell-model.js";
export default {
  components: {
    PageListContainer
  },

  props: {
    duration: Object,
  },

  data() {
    return {
      // 搜索
      startTime: 0,
      endTime: 0,
      keyword: undefined,
      //列表
      dataArray: [],
      total: 0,
      pageSize: undefined,
      currentPage: 1,
      selectedModel: null,
      selectedIndex: undefined,
    };
  },

  computed: {
    hospitalID() {
      return this.$store.state.selectedHospital.hospitalid;
    },
  },

  watch: {
    hospitalID() {
      this.currentPage = 1;
      this.getLogList();
    },

    duration() {
      console.log("🍉", this.duration)
      this.startTime = this.duration.startTime;
      this.endTime = this.duration.endTime;
      this.currentPage = 1;
      this.getLogList();
    }
  },

  mounted() {
    this.startTime = this.duration.startTime;
    this.endTime = this.duration.endTime;
    this.pageSize = bestPageSizeComputer()
    this.getLogList();
  },

  methods: {
    // action
    // 搜索
    onDurationSelected(startTime, endTime) {
      this.startTime = startTime;
      this.endTime = endTime;
      this.currentPage = 1;
      this.getLogList();
    },

    onSearchClick(keyword) {
      this.keyword = keyword
      this.currentPage = 1;
      this.getLogList();
    },

    //cell
    onCellSelectClick: function (index) {
      this.selectedModel = this.dataArray[index];
      this.selectedIndex = index;
    },

    //分页
    onPageChange(currentPage) {
      this.currentPage = currentPage;
      this.getLogList();
    },

    onSizeChange(size) {
      this.pageSize = size
      this.currentPage = 1
      this.getLogList();
    },

    // method
    async getLogList() {
      this.selectedIndex = null;
      this.selectedModel = null;
      console.log("🎦", DateTool.timestampToStr(this.startTime, 'Y-M-D h:m:s'), DateTool.timestampToStr(this.endTime, 'Y-M-D h:m:s'))
      try {
        const params = {
          "hospitalId": this.hospitalID,
          "pageNum": this.currentPage,
          "pageSize": this.pageSize,
          "startTime": DateTool.timestampToStr(this.startTime, 'Y-M-D h:m:s'),
          "endTime": DateTool.timestampToStr(this.endTime, 'Y-M-D h:m:s'),
          "sn": this.keyword,
        };
        const data = await this.$api.getMonitorLogList(params);
        this.dataArray = data.list.map((item) => {
          return new MonitorCellModel(item)
        })
        this.total = data.total;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.monitor {
  font-size: 15px;
  width: 100%;

  &-signal-icon {
    height: 15px;
    margin-left: 20px;
  }

  /deep/.el-table {
    font-size: 12px;
  }
}
</style>
