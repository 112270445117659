<template>
  <list-title-bar :titleArray="titleArray"></list-title-bar>
</template>

<script>
import ListTitleBar from "@c/operation-log/common/paging-list/list-title-bar.vue";

export default {
  components: { ListTitleBar },

  computed: {
    titleArray() {
      return [
        { name: "操作人", percent: "10%" },
        { name: "内容", percent: "40%" },
        { name: "机构", percent: "20%" },
        { name: "时间", percent: "20%" },
        { name: "来源", percent: "20%" },
      ];
    },
  },
};
</script>