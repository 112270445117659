<template>
  <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
    <div class="operate-cell">
      <cell-unit :style="{ width: '10%' }" :title="userName"></cell-unit>
      <cell-unit :style="{ width: '40%' }" :title="content"></cell-unit>
      <cell-unit :style="{ width: '20%' }" :title="organization"></cell-unit>
      <cell-unit :style="{ width: '20%' }" :title="createDate"></cell-unit>
      <cell-unit :style="{ width: '20%' }" :title="source"></cell-unit>
    </div>
  </list-cell>
</template>

<script>
import CellUnit from "@c/operation-log/common/paging-list/cell-unit.vue";
import ListCell from "@c/operation-log/common/paging-list/list-cell.vue";

export default {
  components: {
    ListCell,
    CellUnit,
  },

  props: {
    model: Object,
    isSelected: Boolean,
    index: Number,
  },

  computed: {
    userName() {
      return placeholderText(this.model.operator);
    },

    content() {
      return placeholderText(this.model.content);
    },

    organization() {
      return placeholderText(this.model.hospitalName);
    },

    createDate() {
      return placeholderText(this.model.createTime)
    },

    source() {
      return placeholderText(this.model.userAgent);
    },
  },

  methods: {
    onSelectClick: function () {
      this.$emit("onSelectClick", this.index);
    },
  },
};
</script>

<style lang="scss" scoped>


.operate-cell {
  display: flex;
  align-items: center;
}
</style>