<template>
  <div class="system">
    <page-list-container :total="total" :pageSize="pageSize" :inputCurrentPage="currentPage"
      @onPageChange="onPageChange" @onSizeChange="onSizeChange">
      <div>
        <device-title-bar></device-title-bar>
        <device-cell v-for="(item, index) of dataArray" :key="index" :model="item" :index="index"
          :isSelected="selectedIndex === index" @onSelectClick="onCellSelectClick"
          @onCheckDetailClick="onCheckDetailClick"></device-cell>
      </div>
    </page-list-container>
  </div>
</template>

<script>
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import DeviceTitleBar from "@c/operation-log/device/device-title-bar.vue";
import DeviceCell from "@c/operation-log/device/device-cell.vue";
import { DateTool } from "@js/date-tool.js";
import { ReportPreviewTool } from "@js/report-preview-tool.js";
export default {
  components: {
    PageListContainer,
    DeviceTitleBar,
    DeviceCell,
  },

  props: {
    duration: Object,
  },

  data() {
    return {
      // 搜索
      startTime: 0,
      endTime: 0,
      //列表
      dataArray: [],
      total: 0,
      pageSize: undefined,
      currentPage: 1,
      selectedModel: null,
      selectedIndex: null,
    };
  },

  computed: {
    hospitalID() {
      return this.$store.state.selectedHospital.hospitalid;
    },
  },

  watch: {
    hospitalID() {
      this.currentPage = 1;
      this.getLogList();
    },

    duration() {
      this.startTime = this.duration.startTime;
      this.endTime = this.duration.endTime;
      this.currentPage = 1;
      this.getLogList();
    },
  },

  mounted() {
    this.pageSize = bestPageSizeComputer()
    this.getLogList();
  },

  methods: {
    // action
    // 搜索
    onDurationSelected(startTime, endTime) {
      this.startTime = startTime;
      this.endTime = endTime;
      this.currentPage = 1;
      this.getLogList();
    },

    //cell
    onCellSelectClick(index) {
      this.selectedIndex = index;
      this.selectedModel = this.dataArray[index];
    },

    onCheckDetailClick(model) {
      ReportPreviewTool.previewReport(
        '',
        model.resourceId,
        ''
      );
    },

    //分页
    onPageChange(currentPage) {
      this.currentPage = currentPage;
      this.getLogList();
    },

    onSizeChange(size) {
      this.pageSize = size
      this.currentPage = 1
      this.getLogList();
    },

    // method
    async getLogList() {
      this.selectedIndex = null;
      this.selectedModel = null;
      try {
        const params = {
          "project": '八戒睡眠',
          "hospitalIds": this.hospitalID == null ? null : [this.hospitalID],
          "pageNum": this.currentPage,
          "pageSize": this.pageSize,
          "startTime": DateTool.timestampToStr(this.startTime, 'Y-M-D h:m:s'),
          "endTime": DateTool.timestampToStr(this.endTime, 'Y-M-D h:m:s'),
          "category": '睡眠报告'
        };
        const data = await this.$api.logList(params);
        this.dataArray = data.list;
        this.total = data.total;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.system {
  font-size: 19px;
  width: 100%;
}
</style>