<template>
  <div class="operation-log">
    <filter-bar :itemArray="itemArray" :initialSelectedIndex="selectedComponentIndex" :keywordTitle="keywordTitle"
      v-model="keyword" :hasDatePicker="currentComponent != 'bloodoxy'" :initDuration="duration"
      :selectList="selectList" selectPlaceholder="请选择系统类型" :showLogType="showLogType" @onItemSelected="onItemSelected"
      @onDurationSelected="onDurationSelected" @onSearchClick="onSearchClick" />
    <component ref="component" :is="currentComponent" :duration="componentDuration" :logType="logType"
      :bloodOxygenLogSystemType="bloodOxygenLogSystemType">
    </component>
  </div>
</template>

<script>
import FilterBar from "@c/operation-log/common/filter-bar/filter-bar.vue";
import Operate from "@c/operation-log/operate/operate.vue";
import Monitor from "@c/operation-log/monitor/monitor.vue";
import Device from "@c/operation-log/device/device.vue";
import Bloodoxy from "@c/operation-log/bloodoxy/index.vue";
import Warning from "@c/operation-log/warning/index.vue"
export default {
  components: {
    FilterBar,
    Operate,
    Monitor,
    Device,
    Bloodoxy,
    Warning
  },

  data() {
    return {
      selectedComponentIndex: 0,
      duration: undefined,
      keywordTitle: "关键字",
      keyword: "",
      logType: "",
      showLogType: false,
      bloodOxygenLogSystemType: null,
      selectList: null,
    };
  },

  computed: {
    itemArray(){
      if (this.$store.getters.isSeniorAdministratorOrOperationMember) {
       return ['操作日志', '设备日志', '监测数据','血氧设备日志','设备预警日志']
      } else {
       return ['操作日志', '设备日志', '监测数据']
      }
    },
    currentComponent() {
      switch (this.selectedComponentIndex) {
        case 0:
          return "operate";
        case 1:
          return "device";
        case 2:
          return "monitor";
        case 3:
          return "bloodoxy";
        case 4:
          return "warning";
        default:
          return "";
      }
    },

    componentDuration() {
      if (this.duration) {
        return {
          startTime: this.duration[0] / 1000,
          endTime: this.duration[1] / 1000,
        };
      } else {
        return { startTime: 0, endTime: 0 };
      }
    }
  },

  watch: {
    currentComponent(newValue) {
      if (newValue == "monitor") {
        this.keywordTitle = "设备SN";
      } else if (newValue == "bloodoxy") {
        this.keywordTitle = "关键字";
      } else {
        this.keywordTitle = "";
      }
      switch (newValue) {
        case 'monitor':
          this.keywordTitle = "设备SN";
          break;
        case 'operate':
        case 'warning':
        case 'bloodoxy':
          this.keywordTitle = "关键字";
          break;
        default:
          this.keywordTitle = "";
      }
      if (newValue == "bloodoxy") {
        this.selectList = window.BLOOD_OXYGEN_LOG_SYSTEM_TYPE
        this.showLogType = true
      } else {
        this.selectList = null
        this.showLogType = false
      }
    },
  },

  methods: {
    // 页面切换
    onItemSelected(index) {
      this.selectedComponentIndex = index;
      if (this.selectedComponentIndex == 2 || this.selectedComponentIndex == 4) { //监测信息
        const today = new Date()
        today.setHours(23, 59, 59, 0)
        this.duration = [
          Date.parse(today) - 8 * 24 * 60 * 60 * 1000 + 1000,
          today
        ]
      } else {
        this.duration = undefined;
      }
      this.keyword = ""
    },

    // 搜索
    onSearchClick(keyword) {
      this.$nextTick(() => { this.$refs.component.onSearchClick(keyword) })
    },

    //时间选择
    onDurationSelected(duration) {
      this.duration = duration;
    }
  }

};
</script>

<style lang="scss" scoped>
.operation-log {
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  padding: rem(0) rem(20) rem(0) rem(20);
}
</style>