
class MonitorCellModel {
    constructor(model) {
        this.model = model
    }

    get organization() {
        return placeholderText(this.model.hospitalName);
    }

    get sn() {
        return placeholderText(this.model.sn);
    }

    get ringsn() {
        return placeholderText(this.model.ringSn);
    }

    get createDate() {
        return placeholderText(this.model.createTime)
    }

    get deviceMonitorStatus() {
        const monitorStatus = parseInt(this.model.monitorStatus);
        switch (monitorStatus) {
            case 1:
                return "监测中";
            case 0:
                return "未监测";
            default:
                return "-";
        }
    }

    get ringMonitorStatus() {
        const monitor = parseInt(this.model.monitor);
        switch (monitor) {
            case 1:
                return "监测中";
            case 0:
                return "未监测";
            default:
                return "-";
        }
    }

    get ringWorkStatus() {
        return placeholderText(findKey(RING_MONITOR_STATUS_CODE, this.model.ringStatus));
    }

    get ringPower() {
        const connectStatus = this.model.connectStatus;
        const battery = this.model.battery;
        if (connectStatus == "2" && battery >= 0) {
            //已连接且电量大于等于0
            return `${battery}%`;
        } else {
            return "-";
        }
    }

    get isInBed() {
        const inBed = this.model.inBed;
        switch (inBed) {
            case 1:
                return "否";
            case 2:
                return "是";
            default:
                return "-";
        }
    }

    get netIconURL() {
        const networkType = findKey(MONITOR_NETWORK_TYPE, this.model.networkType);
        const sinnalValue = parseInt(this.model.netWorkRssi);
        let iconValue = "";
        switch (true) {
            case sinnalValue < -90:
                iconValue = "0";
                break;
            case sinnalValue >= -90 && sinnalValue < -80:
                iconValue = "1";
                break;
            case sinnalValue >= -80 && sinnalValue < -70:
                iconValue = "2";
                break;
            case sinnalValue >= -70 && sinnalValue < -60:
                iconValue = "3";
                break;
            default:
                iconValue = "4";
                break;
        }
        switch (networkType) {
            case "移动信号":
                return require(`@imgs/mobile-${iconValue}.png`);
            case "wifi信号":
                return require(`@imgs/wifi-${iconValue}.png`);
            case "mifi信号":
                return require(`@imgs/mifi.png`);
            default:
                return "";
        }
    }

    get monitorDuration() {
        return placeholderText(this.model.period)
    }

    get spo2() {
        return placeholderText(this.model.bloodOxygen, "%")
    }

    get version() {
        return placeholderText(this.model.versionNo)
    }

    get ringVersion() {
        return placeholderText(this.model.swVersion)
    }
}

export { MonitorCellModel }