<template>
  <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
    <div class="system-cell">
      <cell-unit :style="{ width: '15%' }" :title="createDate"></cell-unit>
      <cell-unit :style="{ width: '15%' }" :title="messageType"></cell-unit>
      <cell-unit :style="{ width: '50%' }" :title="content" buttonTitle="查看详情" :isButtonEnabled="isReportComplete" @onButtonClick="onCheckDetailClick">
      </cell-unit>
      <!-- <cell-unit :style="{ width: '10%' }" :title="dataType"></cell-unit> -->
      <cell-unit :style="{ width: '20%' }" :title="organization"></cell-unit>
    </div>
  </list-cell>
</template>

<script>
import CellUnit from "@c/operation-log/common/paging-list/cell-unit.vue";
import ListCell from "@c/operation-log/common/paging-list/list-cell.vue";
export default {
  components: {
    ListCell,
    CellUnit,
  },

  props: {
    index: Number,
    model: Object,
    isSelected: Boolean,
  },

  computed: {
    createDate() {
      return placeholderText(this.model.createTime)
    },

    messageType() {
      return placeholderText(this.model.transaction)
    },

    content() {
      return this.model.content;
    },

    organization() {
      return placeholderText(this.model.hospitalName);
    },

    isReportComplete() {
      return !!this.model.resourceId
    }
  },

  methods: {
    onSelectClick: function () {
      this.$emit("onSelectClick", this.index);
    },

    onCheckDetailClick() {
      if (this.isReportComplete) {
        this.$emit("onCheckDetailClick", this.model);
      }
    },
  },
};
</script>

<style lang="scss" scoped>


.system-cell {
  height: 100%;
  display: flex;
  align-items: center;
}

.slot {
  color: #666666;
  font-size: 14px;
  &-check {
    color: #325FAC;
    border-bottom: 1px solid #325FAC;
  }
}
</style>