class SmartDiagnoseAlertRingLogModel {

    constructor(model) {
        this.model = model
    }

    get updateTime() {
        return placeholderText(this.model.gmtModify)
    }

    get linkArray() {
        return this.model.logContent.split(",").map((item) => {
            return {
                title: this.getFileNameFromURL(item),
                url: item
            }
        })
    }

    getFileNameFromURL(url) {
        const pathArray = url.split('/');
        const fileName = pathArray[pathArray.length - 1].split('?')[0]; // 移除可能存在的查询参数部分
        return fileName;
    }
}

export {SmartDiagnoseAlertRingLogModel}