<template>
  <pop-view @onCloseClick="onCloseClick">
    <div class="add-device">
      <div class="add-device-top">
        <p class="add-device-top-title">
          指环数据上传智能诊断
          <span class="add-device-top-title-tip">*请先保证用户已上传所有日志</span>
        </p>
        <img class="add-device-top-close" src="@imgs/close-card.png" @click="onCloseClick" />
      </div>
      <div class="add-device-content">
        <div class="add-device-content-top">
          <el-input placeholder="请输入设备SN" v-model="sn">
          </el-input>
          <el-date-picker size="mini" v-model="duration" type="datetimerange" value-format="timestamp" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
          <button class="add-device-content-top-diagnose" @click="onDiagnose">开始诊断</button>
        </div>
        <div class="add-device-content-result">
          <div v-if="dataLogArray.length" class="add-device-content-result-data-upload-table">
            <el-table :data="dataLogArray" border style="width: 100%" :row-class-name="tableRowClassName">
              <el-table-column prop="updateTime" label="上传时间" :width="160"></el-table-column>
              <el-table-column prop="errorLog" label="上传结果" :width="120"></el-table-column>
              <el-table-column prop="recoverInfo" label="数据恢复信息"></el-table-column>
            </el-table>
          </div>
          <div v-if="ringLogArray.length" class="add-device-content-result-ring-log-table">
            <p class="add-device-content-result-ring-log-table-result">该指环在查询周期内<span
                style="font-weight: bold; color: #F45C50"> 未上传数据</span>，请根据日志排查原因</p>
            <el-table :data="ringLogArray" border style="width: 100%">
              <el-table-column label="时间" width="200">
                <template slot-scope="scope">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 10px">{{ scope.row.updateTime }}</span>
                </template>
              </el-table-column>
              <el-table-column label="日志文件">
                <template slot-scope="scope">
                  <div class="add-device-content-result-ring-log-table-cell-content">
                    <a v-for="(item, index) in scope.row.linkArray" :key="index" :href="item.url" target="_blank">{{
                      item.title
                      }}</a>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <p v-if="isQueryComplete && !dataLogArray.length && !ringLogArray.length"
            class="add-device-content-result-ring-log-table-result">该指环在查询周期内<span
              style="font-weight: bold; color: #F45C50">
              并未上传任何数据和日志 </span>。</p>
        </div>
      </div>
    </div>
  </pop-view>
</template>

<script>
import PopView from "@c/common/pop-view.vue";
import KeywordSearch from "@c/operation-log/common/filter-bar/keyword-search.vue";
import { Input } from "element-ui";
import { DateTool } from "@js/date-tool.js";
import { SmartDiagnoseAlertDataUploadModel } from "@c/operation-log/bloodoxy/smart-diagnose-alert/smart-diagnose-alert-data-upload-model.js";
import { SmartDiagnoseAlertRingLogModel } from "@c/operation-log/bloodoxy/smart-diagnose-alert/smart-diagnose-alert-ring-log-model.js";

export default {
  components: {
    PopView,
    KeywordSearch,
    ElInput: Input
  },

  model: {
    prop: "isShowAlert",
    event: "onCloseClick"
  },

  data() {
    return {
      //请求
      sn: undefined,
      duration: undefined,
      //列表
      dataLogArray: [],
      ringLogArray: [],
      isQueryComplete: false
    };
  },

  methods: {
    onCloseClick() {
      this.$emit("onCloseClick");
    },

    onDiagnose() {
      if (!this.sn || !this.duration) {
        this.$toast.showRed("查询条件不能为空")
        return
      }
      this.dataLogArray = []
      this.ringLogArray = []
      this.isQueryComplete = false
      this.getDataUploadLogList()
    },

    // method
    tableRowClassName({ row }) {
      if (row.isSucceed) {
        return 'succeed-row'
      } else {
        return 'failed-row'
      }
    },

    async getDataUploadLogList() {
      try {
        const startTime = DateTool.millisecondTimestampToStr(this.duration[0], 'Y-M-D h:m:s')
        const endTime = DateTool.millisecondTimestampToStr(this.duration[1], 'Y-M-D h:m:s')
        const params = {
          "keyWord": this.sn,
          "pageNum": 1,
          "pageSize": 1000,
          "startDate": startTime,
          "endDate": endTime,
          "logType": "原始数据"
        };
        const data = await this.$api.bloodOxygenDeviceLog(params);
        this.dataLogArray = data.list.map((item) => {
          return new SmartDiagnoseAlertDataUploadModel(item)
        });
        if (!this.dataLogArray.length) {
          this.getRingLogList()
        }
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async getRingLogList() {
      try {
        const startTime = DateTool.millisecondTimestampToStr(this.duration[0], 'Y-M-D h:m:s')
        const endTime = DateTool.millisecondTimestampToStr(this.duration[1], 'Y-M-D h:m:s')
        const params = {
          "keyWord": this.sn,
          "pageNum": 1,
          "pageSize": 1000,
          "startDate": startTime,
          "endDate": endTime,
          "logType": "原始日志"
        };
        const data = await this.$api.bloodOxygenDeviceLog(params);
        this.ringLogArray = data.list.map((item) => {
          return new SmartDiagnoseAlertRingLogModel(item)
        });
        this.isQueryComplete = true
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-device {

  &-top {
    width: 100%;
    height: rem(55);
    border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
    box-sizing: border-box;
    padding: rem(0) rem(24);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      color: rgba($color: black, $alpha: 0.85);
      font-size: rem(16);

      &-tip {
        color: #F45C50;
        font-size: 12px;
      }
    }

    &-close {
      width: rem(16);
      height: rem(16);
    }
  }

  &-content {
    padding: 10px 20px 50px;
    width: 750px;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      /deep/.el-input {
        width: 220px;
        margin-right: 20px;
      }

      &-diagnose {
        color: white;
        background-color: #325FAC;
        font-size: 14px;
        width: 88px;
        height: 32px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-result {
      margin-top: 20px;
      height: 400px;
      overflow: hidden;
      overflow-y: auto;

      &-data-upload-table {
        /deep/.el-table td.el-table__cell div {
          white-space: pre-line;
        }

        /deep/.el-table .failed-row {
          color: #F45C50;
        }
      }

      &-ring-log-table {
        display: flex;
        flex-direction: column;

        &-result {
          margin-bottom: 20px;
        }

        &-cell-content {
          flex: 3;
          display: flex;
          flex-direction: column;

          >a {
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>