import { findKey } from "@js/object-tool.js";
class BloodOxyItemModel {

    constructor(model) {
        this.model = model
    }

    get gmtModify() {
        return placeholderText(this.model.gmtModify)
    }

    get gmtCreate() {
        return placeholderText(this.model.gmtCreate)
    }

    get systemType() {
        return BLOOD_OXYGEN_LOG_SYSTEM_TYPE.find(el => el.value === this.model.systemType)?.label
    }

    get logType() {
        return placeholderText(this.model.logType)
    }

    get logContent() {
        switch (this.model.logType) {
            case "操作日志":
                return placeholderText(this.model.logContent)
            default:
                return this.model.logContent.split(",").map((item) => {
                    return {
                        title: this.getFileNameFromURL(item),
                        url: item
                    }
                })
        }
    }

    get errorLog() {
        return placeholderText(this.model.errorLog)
    }

    get ringSn() {
        return placeholderText(this.model.ringSn)
    }

    get ringVersion() {
        return placeholderText(this.model.ringVersion)
    }

    get sdkVersion() {
        return placeholderText(this.model.sdkVersion)
    }

    get deviceModel() {
        return placeholderText(this.model.model)
    }

    get appVersion() {
        return placeholderText(this.model.appVersion)
    }

    get userName() {
        return placeholderText(this.model.userName)
    }

    get userPhone() {
        return placeholderText(this.model.userPhone)
    }

    get hospitalName() {
        return placeholderText(this.model.hospitalName)
    }

    getFileNameFromURL(url) {
        const pathArray = url.split('/');
        const fileName = pathArray[pathArray.length - 1].split('?')[0]; // 移除可能存在的查询参数部分
        return fileName;
    }
}

export { BloodOxyItemModel }