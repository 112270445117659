<template>
    <div class="warning">
        <page-list-container :total="total" :pageSize="pageSize" :inputCurrentPage="currentPage"
            @onPageChange="onPageChange" @onSizeChange="onSizeChange">
            <div v-if="dataArray.length">
                <el-table :data="dataArray" stripe border>
                    <el-table-column fixed prop="sn" label="设备SN" width="130" show-overflow-tooltip />
                    <el-table-column prop="userName" label="用户姓名" width="120" show-overflow-tooltip />
                    <el-table-column prop="monitorDuration" label="监控时间段" width="120" show-overflow-tooltip />
                    <el-table-column prop="warningMsg" label="警告内容" show-overflow-tooltip />
                    <el-table-column prop="hospitalName" label="医院名称" width="160" show-overflow-tooltip />
                    <el-table-column prop="warningCount" label="预警次数" width="80" show-overflow-tooltip />
                    <el-table-column prop="createTime" label="创建时间" width="160" show-overflow-tooltip />
                    <el-table-column prop="updateTime" label="更新时间" width="160" show-overflow-tooltip />
                </el-table>
            </div>
        </page-list-container>


    </div>
</template>

<script>
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import { DateTool } from "@js/date-tool.js";
import { WarningCellModel } from "@c/operation-log/warning/warning-cell-model.js";
export default {
    components: {
        PageListContainer
    },

    props: {
        duration: Object,
    },

    data() {
        return {
            // 搜索
            startTime: 0,
            endTime: 0,
            keyword: undefined,
            //列表
            dataArray: [],
            total: 0,
            pageSize: undefined,
            currentPage: 1,
            selectedModel: null,
            selectedIndex: undefined,
            pageType: "monitor"
        };
    },

    computed: {
        hospitalID() {
            return this.$store.state.selectedHospital.hospitalid;
        },
    },

    watch: {
        hospitalID() {
            this.currentPage = 1;
            this.getLogList();
        },

        duration() {
            this.startTime = this.duration.startTime;
            this.endTime = this.duration.endTime;
            this.currentPage = 1;
            this.getLogList();
        }
    },

    mounted() {
        this.startTime = this.duration.startTime;
        this.endTime = this.duration.endTime;
        this.currentPage = 1;
        this.pageSize = bestPageSizeComputer()
        this.getLogList();
    },

    methods: {
        // action
        // 搜索
        onDurationSelected(startTime, endTime) {
            this.startTime = startTime;
            this.endTime = endTime;
            this.currentPage = 1;
            this.getLogList();
        },

        onSearchClick(keyword) {
            this.keyword = keyword
            this.currentPage = 1;
            this.getLogList();
        },

        //cell
        onCellSelectClick: function (index) {
            this.selectedModel = this.dataArray[index];
            this.selectedIndex = index;
        },

        //分页
        onPageChange(currentPage) {
            this.currentPage = currentPage;
            this.getLogList();
        },

        onSizeChange(size) {
            this.pageSize = size
            this.currentPage = 1
            this.getLogList();
        },

        // method
        async getLogList() {
            this.selectedIndex = null;
            this.selectedModel = null;
            try {
                const params = {
                    "hospitalIds": this.hospitalID ? [this.hospitalID] : undefined,
                    "pageNum": this.currentPage,
                    "pageSize": this.pageSize,
                    "startTime": DateTool.timestampToStr(this.startTime, 'Y-M-D h:m:s'),
                    "endTime": DateTool.timestampToStr(this.endTime, 'Y-M-D h:m:s'),
                    "keyWord": this.keyword,
                };
                const data = await this.$api.getDeviceWarningList(params);
                this.dataArray = data.list.map((item) => {
                    return new WarningCellModel(item)
                })
                this.total = data.total;
            } catch (error) {
                this.$toast.showRed(error);
            }
        },
    },
};
</script>


<style lang="scss" scoped>
.warning {
    font-size: 15px;
    width: 100%;

    &-signal-icon {
        height: 15px;
        margin-left: 20px;
    }

    /deep/.el-table {
        font-size: 12px;
    }
}
</style>