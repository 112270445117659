
class WarningCellModel {
    
    constructor(model) {
        this.model = model
    }

    get sn() {
        return placeholderText(this.model.deviceSn);
    }

    get warningMsg() {
        return placeholderText(this.model.earlyWarningMsg);
    }

    get monitorDuration() {
        return placeholderText(this.model.monitor)
    }

    get userName() {
        return placeholderText(this.model.trueName)
    }

    get hospitalName() {
        return placeholderText(this.model.hospitalName)
    }

    get warningCount() {
        return placeholderText(this.model.warningCount.toFixed())
    }

    get updateTime() {
        return placeholderText(this.model.gmtModify)
    }

    get createTime() {
        return placeholderText(this.model.gmtCreate)
    }
}

export { WarningCellModel }