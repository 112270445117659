<template>
    <div class="bloodoxy">
        <div class="bloodoxy-bar">
            <div class="bloodoxy-bar-left">
                <el-select v-model="systemTypeValue" placeholder="请选择系统类型" clearable>
                    <el-option v-for="item in systemTypeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select style="margin-left: 10px;" v-model="logTypeValue" placeholder="请选择日志类型" clearable>
                    <el-option v-for="item in logTypeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-date-picker style="margin-left: 10px;" v-model="duration" type="datetimerange"
                    value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </div>
            <button class="bloodoxy-bar-diagnose" @click="onSmartDiagnose">智能诊断</button>
        </div>
        <page-list-container :total="total" :pageSize="pageSize" :inputCurrentPage="currentPage"
            @onPageChange="onPageChange" @onSizeChange="onSizeChange">
            <div class="bloodoxy-table">
                <el-table :data="dataArray" stripe style="width: 100%" border>
                    <el-table-column prop="systemType" label="类型" width="80" />
                    <el-table-column prop="logType" label="日志类型" width="100" />
                    <el-table-column prop="logContent" label="日志内容" width="180">
                        <template slot-scope="scope">
                            <p v-if="scope.row.logType == '操作日志'">{{ scope.row.logContent }}</p>
                            <div v-else class="bloodoxy-table-cell-content">
                                <a v-for="(item, index) in scope.row.logContent" :key="index" :href="item.url"
                                    target="_blank">{{
                                        item.title
                                    }}</a>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="errorLog" label="日志信息" width="150">
                        <template slot-scope="scope">
                            <el-popover placement="top" width="500" trigger="hover">
                                <p>{{ scope.row.errorLog }}</p>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="primary"
                                        @click="duplicateContent(scope.row.errorLog)">复制</el-button>
                                </div>
                                <span slot="reference">{{ (scope.row.errorLog && scope.row.errorLog?.length > 30) ?
                                    scope.row.errorLog?.substring(0, 30) +
                                    '...' : scope.row.errorLog }}</span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ringSn" label="戒指sn" width="130" show-overflow-tooltip />
                    <el-table-column prop="ringVersion" label="戒指版本" width="80" show-overflow-tooltip />
                    <el-table-column prop="sdkVersion" label="sdk版本" width="80" show-overflow-tooltip />
                    <el-table-column prop="deviceModel" label="机型" width="120" show-overflow-tooltip />
                    <el-table-column prop="appVersion" label="app版本号" width="80" show-overflow-tooltip />
                    <el-table-column prop="userName" label="用户姓名" width="80" show-overflow-tooltip />
                    <el-table-column prop="userPhone" label="用户手机号" width="110" show-overflow-tooltip />
                    <el-table-column prop="hospitalName" label="医院名称" width="150" show-overflow-tooltip />
                    <el-table-column prop="gmtModify" label="更新时间" width="150" fixed />
                    <el-table-column prop="gmtCreate" label="创建时间" width="150" />
                </el-table>
            </div>
        </page-list-container>
        <smart-diagnose-alert v-if="isShowSmartDignoseAlert"
            v-model:isShowAlert="isShowSmartDignoseAlert"></smart-diagnose-alert>
    </div>
</template>

<script>
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import { DateTool } from "@js/date-tool.js";
import { Select, Option, Input, Table, TableColumn, Popover, Button } from 'element-ui'
import SmartDiagnoseAlert from "@c/operation-log/bloodoxy/smart-diagnose-alert/smart-diagnose-alert.vue";
import { BloodOxyItemModel } from "@c/operation-log/bloodoxy/bloodoxy-item-model.js";
export default {
    components: {
        PageListContainer,
        ElSelect: Select,
        ELOption: Option,
        ElInput: Input,
        ElTable: Table,
        ElTableColumn: TableColumn,
        ElPopover: Popover,
        ElButton: Button,
        SmartDiagnoseAlert
    },

    data() {
        return {
            //筛选
            duration: undefined,
            startTime: 0,
            endTime: 0,
            keyword: undefined,
            systemTypeValue: undefined,
            systemTypeList: window.BLOOD_OXYGEN_LOG_SYSTEM_TYPE,
            logTypeValue: undefined,
            logTypeList: window.BLOOD_OXYGEN_LOG_TYPE,
            //列表
            dataArray: [],
            total: 0,
            pageSize: undefined,
            currentPage: 1,
            BLOOD_OXYGEN_LOG_SYSTEM_TYPE: window.BLOOD_OXYGEN_LOG_SYSTEM_TYPE,
            //弹窗
            isShowSmartDignoseAlert: false
        };
    },

    watch: {
        duration(value) {
            this.startTime = value ? value[0] : undefined;
            this.endTime = value ? value[1] : undefined
            this.currentPage = 1;
            this.getLogList();
        },

        systemTypeValue() {
            this.currentPage = 1;
            this.getLogList();
        },

        logTypeValue() {
            this.currentPage = 1;
            this.getLogList();
        }
    },


    mounted() {
        this.pageSize = bestPageSizeComputer()
        this.getLogList();
    },

    methods: {
        onSearchClick(keyword) {
            this.keyword = keyword
            this.currentPage = 1;
            this.getLogList();
        },

        //分页
        onPageChange(currentPage) {
            this.currentPage = currentPage;
            this.getLogList();
        },

        onSizeChange(size) {
            this.pageSize = size
            this.currentPage = 1
            this.getLogList();
        },

        onSmartDiagnose() {
            this.isShowSmartDignoseAlert = true
        },

        // method
        async getLogList() {
            this.selectedIndex = null;
            this.selectedModel = null;
            try {
                const params = {
                    "keyWord": this.keyword,
                    "pageNum": this.currentPage,
                    "pageSize": this.pageSize,
                    "startDate": DateTool.millisecondTimestampToStr(this.startTime, 'Y-M-D h:m:s'),
                    "endDate": DateTool.millisecondTimestampToStr(this.endTime, 'Y-M-D h:m:s'),
                    "systemType": this.systemTypeValue,
                    logType: this.logTypeValue
                };
                const data = await this.$api.bloodOxygenDeviceLog(params);
                this.dataArray = data.list.map((item) => {
                    return new BloodOxyItemModel(item)
                })
                this.total = data.total;
            } catch (error) {
                this.$toast.showRed(error);
            }
        },

        duplicateContent(content) {
            navigator.clipboard?.writeText(content);
            this.$toast.showGreen('已复制到剪贴板！')
        }
    },
};
</script>


<style lang="scss" scoped>
.bloodoxy {
    font-size: 19px;
    width: 100%;

    &-bar {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-left {
            display: flex;
        }

        &-diagnose {
            color: white;
            background-color: #325FAC;
            font-size: 14px;
            width: 88px;
            height: 32px;
            border-radius: 4px;
            margin-left: 74px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    &-table {
        display: flex;
        flex-direction: column;

        /deep/.el-table {
            font-size: 12px;
        }

        &-cell-content {
            flex: 3;
            display: flex;
            flex-direction: column;

            >a {
                line-height: 30px;
            }
        }
    }
}
</style>