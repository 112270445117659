class SmartDiagnoseAlertDataUploadModel {
  constructor(model) {
    this.model = model;
  }

  get updateTime() {
    return placeholderText(this.model.gmtModify)
  }

  get errorLog() {
    if (this.isSucceed) {
      return placeholderText(this.model.errorLog)
    } else {
      return `数据上传失败(${placeholderText(this.model.errorLog)})`
    }
    
  }

  get isSucceed(){
    return this.model.errorLog && this.model.errorLog == '数据上传成功'
  }

  get recoverInfo() {
    if (this.isSucceed) {
      return undefined
    } else {
      return `uid:${placeholderText(this.model.userId)}\n sn:${placeholderText(this.model.ringSn)}\n url:${this.model.logContent}`
    }
  }
}

export { SmartDiagnoseAlertDataUploadModel }